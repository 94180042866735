import { PropsWithChildren, ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useGlobalStore } from "../../../../store";
import { QueryClientProvider } from "@tanstack/react-query";
import { sharedQueryClient } from "@/app";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "@/services/feature-toggle-adapter";
interface Props {
  children: ReactElement;
  to?: string;
}

const WrapperProvider = ({ children }: PropsWithChildren<any>) => {
  return (
    <QueryClientProvider client={sharedQueryClient}>
      {children}
    </QueryClientProvider>
  );
};

export default function RedirectIfNotRetailMember(props: Props) {
  const { state } = useGlobalStore();

  const isThirdPartyMember = state.isThirdPartyMember;
  const isRetailMember = state.currentUser.isRetailMember;
  const isSensUpdatesEnabled = isFeatureEnabled(
    FeatureToggleIdentifier.ENABLE_SENS_UPDATES
  );

  const shouldRedirect =
    isThirdPartyMember || (isSensUpdatesEnabled && !isRetailMember);

  return shouldRedirect ? (
    <WrapperProvider>
      <Navigate to="/home" state={{ isRedirected: true }} replace />
    </WrapperProvider>
  ) : (
    <WrapperProvider>{props.children}</WrapperProvider>
  );
}
