import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "@/services/feature-toggle-adapter";
import { Navigate, Outlet } from "react-router-dom";
import { useGlobalStore } from "../../../../store";

export default function RedirectIfNotB2BMember() {
  const { state } = useGlobalStore();

  const isThirdPartyMember = state.isThirdPartyMember;
  const isRetailMember = state.currentUser.isRetailMember;
  const isSensUpdatesEnabled = isFeatureEnabled(
    FeatureToggleIdentifier.ENABLE_SENS_UPDATES
  );

  const shouldRedirect =
    isThirdPartyMember === true || (isSensUpdatesEnabled && isRetailMember);

  return shouldRedirect ? (
    <Navigate to="/home" state={{ isRedirected: true }} replace />
  ) : (
    <Outlet />
  );
}
