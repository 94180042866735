import AuthPage from "@/features/Auth/pages/AuthPage";
import ChatBotPage from "@/features/ChatBot/pages/ChatBotPage";
import MedicalAssistancePage from "@/features/ChatForMedicalAssistance/pages/MedicalAssistancePage";
import CollectUserQueryFlow from "@/features/Common/components/CollectUserQuery";
import GetHelpModal from "@/features/Common/components/GetHelpModal";
import HandleExpiredSession from "@/features/Common/components/HandleExpiredSession";
import MaxWidthContainer from "@/features/Common/components/MaxWidthContainer";
import PaymentFailure from "@/features/Common/components/PaymentFailure";
import RedirectHandler from "@/features/Common/components/RedirectHandler";
import RedirectIfAuthenticated from "@/features/Common/components/RedirectIfAuthenticated";
import RedirectIfNotAuthenticated from "@/features/Common/components/RedirectIfNotAuthenticated";
import RedirectIfThirdPartyMember from "@/features/Common/components/RedirectIfThirdPartyMember";
import SuspenseFallback from "@/features/Common/components/SuspenseFallback";
import MaintenancePage from "@/features/Common/pages/MaintenancePage";
import MedicalAssistanceUnsupportedPage from "@/features/Common/pages/MedicalAssistanceUnsupportedPage";
import DashboardPage from "@/features/Dashboard/pages/DashboardPage";
import BinahScanFlow from "@/features/HealthCheck/components/BinahScanFlow";
import InterCareMedicalAssistanceFlow from "@/features/MedicalConsultations/components/IntercareMedicalAssistance";
import ThirdPartyMedicalAssistanceFlow from "@/features/MedicalConsultations/components/ThirdPartyMedicalAssistanceFlow";
import MemberRequiredActionsPage from "@/features/MemberRequiredActions/pages/MemberRequiredActionsPage";
import MembershipAddDependentsFlow from "@/features/Membership/components/MembershipAddDependents";
import MembershipContactDetailsPage from "@/features/Membership/pages/MembershipContactDetailsPage";
import MembershipDetailPage from "@/features/Membership/pages/MembershipDetailPage";
import MembershipManagementPage from "@/features/Membership/pages/MembershipManagementPage";
import MembershipPolicyDocumentsPage from "@/features/Membership/pages/MembershipPolicyDocumentsPage";
import MembershipTermsPage from "@/features/Membership/pages/MembershipTermsPage";
import MessageDetailsPage from "@/features/Messages/pages/MessageDetailsPage";
import MessagesPage from "@/features/Messages/pages/MessagesPage";
import GetSupport from "@/features/More/components/GetSupport";
import AboutAppPage from "@/features/More/pages/AboutAppPage";
import CommunicationPreferencesPage from "@/features/More/pages/CommunicationPreferencesPage";
import MorePage from "@/features/More/pages/MorePage";
import OpenSourceLicensesPage from "@/features/More/pages/OpenSourceLicensesPage";
import MyBenefitsPage from "@/features/MyBenefits/pages/MyBenefitsPage";
import MyHealthPage from "@/features/MyHealth/pages/MyHealthPage";
import HealthMeasurementDetailPage from "@/features/MyHealth/pages/MyHealthPage/HealthMeasurementDetailPage";
import HealthMeasurementPage from "@/features/MyHealth/pages/MyHealthPage/HealthMeasurementPage";
import WelcomePage from "@/features/Welcome/pages/WelcomePage";
import MyPurchasesPage from "@/features/More/pages/MyPurchasesPage";
import { trackPageView } from "@/services/analytics-adapter";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "@/services/feature-toggle-adapter";
import { maxWidthConfig } from "@/theme";
import {
  lazy,
  LazyExoticComponent,
  Suspense,
  useEffect,
  useState,
} from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import PathologyFlow from "@/features/Pathology/components/PathologyFlow";
import UnsubscribePage from "@/features/Unsubscribe/pages/UnsubscribePage";
import RedirectIfNotB2BMember from "@/features/Common/components/RedirectIfNotB2BMember";
import MyProfilePage from "@/features/MyProfile/pages";
import RedirectIfNotRetailMember from "@/features/Common/components/RedirectIfNotRetailMember";

interface PlaygroundPageProps {
  changeMaxWidth: (arg: string) => void;
}
interface PublicBinahScanFlowProps {
  onCompletedFlow?: () => void;
}

let PlaygroundPage: LazyExoticComponent<React.FC<PlaygroundPageProps>> | null =
  null;

const PublicBinahScanFlow: LazyExoticComponent<
  React.FC<PublicBinahScanFlowProps>
> = lazy(() => import("@/features/HealthCheck/components/PublicBinahScanFlow"));

if (isFeatureEnabled(FeatureToggleIdentifier.SHOW_PLAYGROUND)) {
  PlaygroundPage = lazy(
    () => import("../features/Playground/pages/PlaygroundPage")
  );
}

let previousLocation = "";
const isMaintenanceMode = isFeatureEnabled(
  FeatureToggleIdentifier.ENABLE_MAINTENANCE_MODE
);

export default function Router() {
  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state as {
    from: string;
    isRedirected: boolean;
  };
  const from = locationState && locationState.from;
  const [maxWidth, setMaxWidth] = useState(maxWidthConfig.defaultMaxWidth);

  useEffect(() => {
    trackPageView({
      "page.url": location.pathname,
      "page.sourceUrl": previousLocation,
      other: { ...location.state },
    });
    previousLocation = location.pathname + location.search;
  }, [location]);

  function changeMaxWidth(maxWidth: string) {
    setMaxWidth(maxWidth);
  }

  return (
    <ScrollToTop>
      <MaxWidthContainer maxWidth={maxWidth}>
        <RedirectHandler>
          {isMaintenanceMode ? (
            <Routes>
              <Route path="*" element={<MaintenancePage />} />
              <Route
                path="/get-help/log-query"
                element={<CollectUserQueryFlow />}
              />
              <Route
                path="/unsubscribe"
                element={<UnsubscribePage changeMaxWidth={changeMaxWidth} />}
              />
            </Routes>
          ) : (
            <>
              <Routes location={from || location}>
                <Route
                  index
                  element={
                    <RedirectIfAuthenticated>
                      <WelcomePage />
                    </RedirectIfAuthenticated>
                  }
                />
                <Route
                  path="/login"
                  element={
                    <RedirectIfAuthenticated>
                      <AuthPage />
                    </RedirectIfAuthenticated>
                  }
                />
                <Route
                  path="/member-required-actions"
                  element={<MemberRequiredActionsPage />}
                />

                <Route element={<RedirectIfNotAuthenticated />}>
                  <Route path="/home" element={<DashboardPage />} />

                  <Route
                    path="/home/my-health/health-profile"
                    element={<MyHealthPage />}
                  />
                  <Route
                    path="/home/my-health/health-record"
                    element={<MyHealthPage />}
                  />
                  <Route
                    path="/home/my-health/contact-details"
                    element={<MyHealthPage />}
                  />
                  <Route
                    path="/home/my-health/communication-preferences"
                    element={<CommunicationPreferencesPage />}
                  />

                  <Route
                    path="/home/help/about-app"
                    element={<AboutAppPage />}
                  />

                  <Route
                    path="/home/more/get-support"
                    element={<GetSupport />}
                  />

                  <Route
                    path="/home/help/about-app/open-source-licenses"
                    element={<OpenSourceLicensesPage />}
                  />

                  <Route
                    path="/home/my-benefits/benefits"
                    element={<MyBenefitsPage />}
                  />

                  <Route element={<RedirectIfNotB2BMember />}>
                    <Route
                      path="/home/my-benefits/membership"
                      element={<MyBenefitsPage />}
                    />
                    <Route
                      path="/home/memberships/:membershipIdentifier"
                      element={<MembershipDetailPage />}
                    />
                    <Route
                      path="/home/memberships/:membershipIdentifier/contact"
                      element={<MembershipContactDetailsPage />}
                    />
                    <Route
                      path="/home/memberships/:membershipIdentifier/manage"
                      element={<MembershipManagementPage />}
                    />
                    <Route
                      path="/home/memberships/:membershipIdentifier/terms"
                      element={<MembershipTermsPage />}
                    />
                    <Route
                      path="/home/memberships/:membershipIdentifier/policy-documents"
                      element={<MembershipPolicyDocumentsPage />}
                    />
                  </Route>

                  <Route
                    path="/home/get-medical-assistance"
                    element={<MedicalAssistancePage />}
                  />
                  {isFeatureEnabled(
                    FeatureToggleIdentifier.ENABLE_INTERCARE_MEDICAL_ASSISTANCE
                  ) && (
                    <>
                      <Route
                        path="/home/get-medical-assistance/intercare"
                        element={<InterCareMedicalAssistanceFlow />}
                      />

                      <Route
                        path="/home/get-medical-assistance/intercare/payment-failure"
                        element={<PaymentFailure />}
                      />

                      <Route
                        path="/home/get-medical-assistance/intercare/:id"
                        element={<InterCareMedicalAssistanceFlow />}
                      />
                    </>
                  )}
                  {isFeatureEnabled(
                    FeatureToggleIdentifier.ENABLE_THIRD_PARTY_AUTHENTICATION
                  ) && (
                    <>
                      <Route
                        path="/home/get-medical-assistance/third-party"
                        element={<ThirdPartyMedicalAssistanceFlow />}
                      />
                    </>
                  )}

                  {isFeatureEnabled(
                    FeatureToggleIdentifier.ENABLE_CHAT_BOT
                  ) && (
                    <Route
                      path="/home/sweet-life/chat/:chatBotType"
                      element={<ChatBotPage />}
                    />
                  )}

                  {isFeatureEnabled(
                    FeatureToggleIdentifier.ENABLE_PATHOLOGY_PANELS
                  ) && (
                    <>
                      <Route
                        path="/home/more/my-purchases"
                        element={<MyPurchasesPage />}
                      />
                      <Route
                        path="/home/get-medical-assistance/pathology"
                        element={<PathologyFlow />}
                      />
                    </>
                  )}

                  {isFeatureEnabled(
                    FeatureToggleIdentifier.ENABLE_SENS_UPDATES
                  ) && (
                    <Route
                      path="/home/my-profile"
                      element={
                        <RedirectIfNotRetailMember>
                          <MyProfilePage />
                        </RedirectIfNotRetailMember>
                      }
                    />
                  )}

                  <Route
                    path="home/get-medical-assistance/unsupported"
                    element={<MedicalAssistanceUnsupportedPage />}
                  />
                  <Route path="/home/more" element={<MorePage />} />
                  <Route
                    path="/home/health-measurement/:healthMeasurementIdentifier"
                    element={<HealthMeasurementPage />}
                  />
                  <Route
                    path="/home/health-measurement/:healthMeasurementIdentifier/detail"
                    element={<HealthMeasurementDetailPage />}
                  />

                  <Route
                    path="/home/memberships/:membershipIdentifier/add-people"
                    element={
                      <RedirectIfThirdPartyMember>
                        <MembershipAddDependentsFlow />
                      </RedirectIfThirdPartyMember>
                    }
                  />
                  <Route
                    path="/binah-scan"
                    element={
                      <BinahScanFlow
                        onCompletedFlow={() => navigate("/home")}
                      />
                    }
                  />
                  <Route path="/home/messages" element={<MessagesPage />} />
                  <Route
                    path="/home/messages/:messageId"
                    element={<MessageDetailsPage />}
                  />
                </Route>

                <Route
                  path="/auth-session-error"
                  element={<HandleExpiredSession />}
                />
                <Route
                  path="/playground/*"
                  element={
                    <Suspense fallback={<SuspenseFallback />}>
                      {PlaygroundPage ? (
                        <PlaygroundPage changeMaxWidth={changeMaxWidth} />
                      ) : (
                        <Navigate to="/" replace />
                      )}
                    </Suspense>
                  }
                />
                <Route
                  path="*"
                  element={<Navigate to="/" state={{ isRedirected: true }} />}
                />
                <Route
                  path="/public-binah-scan"
                  element={
                    <Suspense fallback={<SuspenseFallback />}>
                      <PublicBinahScanFlow
                        onCompletedFlow={() => navigate("/public-binah-scan")}
                      />
                    </Suspense>
                  }
                />
                <Route
                  path="/get-help/log-query"
                  element={<CollectUserQueryFlow />}
                />
                <Route
                  path="/unsubscribe"
                  element={<UnsubscribePage changeMaxWidth={changeMaxWidth} />}
                />
              </Routes>
              <Routes>
                <Route path="/get-help" element={<GetHelpModal />} />
              </Routes>

              {from && (
                <Routes>
                  <Route
                    path="/home/get-medical-assistance/prompt"
                    element={<MedicalAssistancePage showInformationPrompt />}
                  />
                </Routes>
              )}
            </>
          )}
        </RedirectHandler>
      </MaxWidthContainer>
    </ScrollToTop>
  );
}
