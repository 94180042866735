import Breakout from "@/features/Common/components/Breakout";
import DefaultError from "@/features/Common/components/DefaultError";
import FullscreenLoadingIndicator from "@/features/Common/components/FullscreenLoadingIndicator";
import PaymentReceipt from "@/features/Common/components/PaymentReceipt";
import WhatsappBreakout from "@/features/Common/components/WhatsappBreakout";
import { getConfigurationVariable } from "@/services/configuration-adapter";
import { PathologyPanel } from "@/services/core-api-adapter";
import { Stack, Typography } from "@mui/material";
import { useMachine } from "@xstate/react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import PathologyOrderSummary from "../PathologyOrderSummary";
import PathologyPanelDetails from "../PathologyPanelDetails";
import PathologyPanels from "../PathologyPanels";
import { PathologyPreparePrompt } from "../PathologyPreparePrompt";
import { PathologyPrompt } from "../PathologyPrompt";
import { eventNames, pathologyFlowStateMachine } from "./machine";

const CHAT_BOT_WHATSAPP_PHONE_NUMBER = getConfigurationVariable(
  "VITE_APP_AMPATH_CHAT_BOT_PHONE_NUMBER_ZA"
);

export default function PathologyFlow() {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const panelId = searchParams.get("panelId");
  const orderId = searchParams.get("orderId");

  const [pathologyStateMachine, pathologyStateMachineDispatch] = useMachine(
    pathologyFlowStateMachine,
    {
      input: {
        context: {
          panelId: panelId || null,
          orderId: orderId || null,
        },
      },
    } as any
  );

  function onGoHomeButtonClick() {
    pathologyStateMachineDispatch({ type: eventNames.GO_HOME_BUTTON_CLICKED });
  }

  function onPanelClick(panel: PathologyPanel) {
    pathologyStateMachineDispatch({
      type: eventNames.BLOOD_PANEL_SELECTED,
      output: panel,
    });
  }

  function onBuyNowButtonClick() {
    pathologyStateMachineDispatch({
      type: eventNames.BUY_NOW_BUTTON_CLICKED,
    });
  }

  function onBackButtonClick() {
    pathologyStateMachineDispatch({
      type: eventNames.BACK_BUTTON_CLICKED,
    });
  }

  function onViewResultsClick() {
    pathologyStateMachineDispatch({
      type: eventNames.VIEW_RESULTS_BUTTON_CLICKED,
    });
  }

  function onContinueButtonClick() {
    pathologyStateMachineDispatch({
      type: eventNames.CONTINUE_BUTTON_CLICKED,
    });
  }

  function onViewPaymentReceiptClick() {
    pathologyStateMachineDispatch({
      type: eventNames.VIEW_RECEIPT_BUTTON_CLICKED,
    });
  }

  function onHowToPrepareClick() {
    pathologyStateMachineDispatch({
      type: eventNames.HOW_TO_PREPARE_BUTTON_CLICKED,
    });
  }

  function onBreakoutDisclaimerContinue() {
    pathologyStateMachineDispatch({
      type: eventNames.USER_REDIRECTED,
    });
  }

  function onStartDoctorConsultationClick() {
    navigate("/home/get-medical-assistance/intercare");
    pathologyStateMachineDispatch({
      type: eventNames.USER_REDIRECTED,
    });
  }

  function getWhatsappBreakoutURL() {
    return `https://wa.me/${CHAT_BOT_WHATSAPP_PHONE_NUMBER}`;
  }

  return (
    <>
      {pathologyStateMachine.matches("fetchingBloodPanelList") ||
        pathologyStateMachine.matches("creatingOrder") ||
        pathologyStateMachine.matches("waitingForOrderOutcome") ||
        pathologyStateMachine.matches("fetchingOrderDetails") ||
        (pathologyStateMachine.matches("fetchingBloodPanelDetails") && (
          <FullscreenLoadingIndicator />
        ))}

      {pathologyStateMachine.matches("showingBloodPanelList") && (
        <PathologyPanels
          panelList={pathologyStateMachine.context.bloodPanelListResponse}
          onPanelClick={onPanelClick}
          onStartDoctorConsultationClick={onStartDoctorConsultationClick}
        />
      )}

      {pathologyStateMachine.matches("showingBloodPanelDetails") &&
        pathologyStateMachine.context.selectedBloodPanel && (
          <PathologyPanelDetails
            onBuyButtonClick={onBuyNowButtonClick}
            onBackButtonClick={onBackButtonClick}
            panel={pathologyStateMachine.context.selectedBloodPanel}
          />
        )}

      {pathologyStateMachine.matches("showingPrompt") &&
        pathologyStateMachine.context.selectedBloodPanel && (
          <PathologyPrompt
            amountTotalFormatted={
              pathologyStateMachine.context.selectedBloodPanel
                ?.amountTotalFormatted
            }
            onContinueButtonClick={onContinueButtonClick}
            onBackButtonClick={onBackButtonClick}
          />
        )}

      {pathologyStateMachine.matches("breakoutScreen") && (
        <Stack sx={{ height: "98vh" }}>
          <Breakout
            type="Ecentric"
            onConfirm={onBreakoutDisclaimerContinue}
            redirectUrl={""}
            onDecline={onBackButtonClick}
          />
        </Stack>
      )}

      {pathologyStateMachine.matches("showingOrderSummary") &&
        pathologyStateMachine.context.fetchOrderDetailsResponse && (
          <PathologyOrderSummary
            order={pathologyStateMachine.context.fetchOrderDetailsResponse}
            onViewResultsClick={onViewResultsClick}
            onViewReceiptClick={onViewPaymentReceiptClick}
            onBackButtonClick={onBackButtonClick}
            onHowToPrepareClick={onHowToPrepareClick}
          />
        )}

      {pathologyStateMachine.matches("error") && (
        <Stack height="100vh" justifyContent="end">
          <DefaultError onComeBackLater={onGoHomeButtonClick}>
            <Typography variant="h2">{t("GenericError.title")}</Typography>
            <Typography pb={2}>{t("GenericError.content")}</Typography>
          </DefaultError>
        </Stack>
      )}

      {pathologyStateMachine.matches("showingPaymentReceipt") &&
        pathologyStateMachine.context.fetchOrderDetailsResponse && (
          <PaymentReceipt
            amountFormatted={
              pathologyStateMachine.context.fetchOrderDetailsResponse
                ?.orderPriceFormatted
            }
            transactionDate={
              pathologyStateMachine.context.fetchOrderDetailsResponse?.created
            }
            referenceNumber={
              pathologyStateMachine.context.fetchOrderDetailsResponse
                ?.orderNumber
            }
            merchantId="0000000035792468"
            cardNumber="**** **** **** 1234"
            onBackButtonClick={onBackButtonClick}
          />
        )}

      {pathologyStateMachine.matches("showingHowToPreparePrompt") && (
        <PathologyPreparePrompt onBackButtonClick={onBackButtonClick} />
      )}

      {pathologyStateMachine.matches("showingWhatsappBreakout") && (
        <Stack height="100vh" p={2}>
          <WhatsappBreakout
            url={getWhatsappBreakoutURL()}
            provider="Ampath"
            logo="/assets/MedicalAssistancePartners/Ampath/logo-color.svg"
            onBackButtonClick={onBackButtonClick}
          />
        </Stack>
      )}

      {pathologyStateMachine.matches("exit") && <Navigate to="/" />}
    </>
  );
}
